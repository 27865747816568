export const API_ROOT_URL = 'https://api.blissfulapps.com'
export const API_URL = 'https://api.blissfulapps.com/api'

export const APP = 'zipcodeverifier.blissfulapps.com'

export const PARAMS = {
    'override': false,
    'shop_url': 'blissful-apps.myshopify.com',
    'hash': 'alslIUhdlhaw1h2l3h1o23789qw8d'
}

